export const regex = {
  accountId: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,8}$/g,
  phone: /^3\d{9}$/g,
  password:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/g,
};

export const errorMessages = {
  accountId: 'Please enter the correct email address',
  phone:
    'This is a mandatory field and can only contain a valid phone number (3**********)',
  password:
    'This is a mandatory field. Passwords must be at least 8 characters long and must contain an upper-case letter, a lower-case letter, a number and a special character with no spaces',
  confirmPassword: 'Passwords do not match',
  invalidAccountId: 'Please enter the correct email address',
};
