import React, { useEffect } from 'react';
import ContentList from '../../components/content-list';

import style from '../UserContent/userContentStyle.module.css';
import { sportsApi } from '../../services/content-apis/sports';
import Loading from '../../components/Loading/Loading';

import { Dispatch, SetStateAction } from 'react';
interface UserSportsProps {
  genre: string;
  setPlayerUrl: Dispatch<SetStateAction<string>>;
  setDRMToken?: Dispatch<SetStateAction<string>>;
  setPlayerTitle?: Dispatch<SetStateAction<string>>;
  setPlayerMediaID?: Dispatch<SetStateAction<string>>;
}

const UserSports: React.FC<UserSportsProps> = ({
  genre,
  setPlayerUrl,
  setPlayerTitle,
  setPlayerMediaID,
}) => {
  const [data, setData] = React.useState<any[]>([]);
  const [responseHasFeatured, setResponseHasFeatured] =
    React.useState<boolean>(true);
  const [showLoader, setShowLoader] = React.useState(true);

  useEffect(() => {
    const getSports = async () => {
      sportsApi.getAll(genre).then((response: any) => {
        setResponseHasFeatured(
          response.some((item: any) => item.carousel === 'Featured')
        );
        setData(response);
      });
    };

    getSports();
  }, [genre]);

  return (
    <>
      {!responseHasFeatured && <div className={style.placeholder}></div>}
      {data &&
        data.length > 0 &&
        data[0]?.carousel ?
        data.map(({ priority, carousel, series }, index) => (
          <ContentList
            key={'sports-' + carousel + '-' + index}
            priority={priority}
            carousel={carousel}
            list={series}
            type="sports"
            setPlayerUrl={setPlayerUrl}
            setPlayerTitle={setPlayerTitle}
            setPlayerMediaID={setPlayerMediaID}
            showLoader={showLoader}
            setShowLoader={setShowLoader}
          />
        )) : <Loading show={showLoader} />}
    </>
  );
};

export default UserSports;
